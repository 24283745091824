.pdf-tracker {
    padding: 0; /* Remove all padding */
    max-width: 100%; /* Make it full width */
    margin: 0; /* Remove margin */
    font-family: Arial, sans-serif;
}

.title {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
}

.loading-spinner {
    text-align: center;
    font-size: 18px;
}

.pdf-cards {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 columns */
    gap: 20px; /* Spacing between cards */
}

.pdf-card {
    background: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.pdf-card h3 {
    margin-top: 0;
}

.view-button {
    display: inline-block;
    margin-top: 10px;
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border-radius: 4px;
    text-decoration: none;
}

.view-button:hover {
    background-color: #0056b3;
}
