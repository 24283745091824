body, html, #root {
  height: 100%;
  margin: 0;
}

.app {
  display: flex;
  height: 100vh;
  background-color: #f0f0f0;
}

.sidebar {
  width: 300px; /* Adjusted width for sidebar */
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background: white;
  border-radius: 5px;
  overflow-y: auto; /* Ensure overflow for long lists */
}

.well-card {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0;
  display: flex;
  align-items: center;
}

.well-card input[type="checkbox"] {
  margin-right: 10px;
}

.canvas-container {
  flex: 1; /* Fill the remaining space */
  top : 55px;
  position: relative;
  overflow-x: auto; /* Enable horizontal scrolling */
  overflow-y: auto;
  border: 1px solid #ccc;
}

.detail-panel {
  width: 300px; /* Fixed width for detail panel */
  padding: 20px;
  background-color: #f8f9fa;
  border-left: 1px solid #ccc;
  position: relative; /* Relative positioning */
  overflow-y: auto; /* Allow scrolling if content overflows */
}

.tooltip {
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  color: black;
  padding: 8px;
  border-radius: 5px;
  font-size: 12px;
  pointer-events: none; /* Prevent interference with clicks */
  z-index: 10; /* Ensure it appears above other elements */
}

/* Optional styles for better visuals */
.detail-panel h4, .detail-panel h5, .detail-panel p {
  margin: 5px 0; /* Uniform margin for headings and paragraphs */
}


.offset-analysis {
  display: flex;
}

.canvas-container {
  position: relative;
}

.tooltip {
  position: absolute;
  /* background-color: white; */
  border: 1px solid #ccc;
  padding: 5px;
  z-index: 10;
  white-space: nowrap;
}

.right-panel {
  margin-top : 55px;
  margin-left: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  width: 20%;
}

/* .wells-list {
  margin-left: 20px;
} */


.offset-analysis-container {
  display: flex;
  justify-content: space-between;
}



.tooltip {
  position: absolute;
  background-color: black;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 5px;
  pointer-events: none;
  z-index: 1000;
}

.wells-selection {
  margin-left: 20px;
}

.formation-description {
  margin-left: 20px;
}



h3 {
  color: #2c3e50;
  margin-bottom: 10px;
}

.complication {
  top : 55px;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 15px;
}

h4 {
  color: #e74c3c;
  margin: 0;
}

p {
  margin: 5px 0;
}
